import nativeAxios from 'axios';
import jwtDecode from 'jwt-decode';
import noop from 'lodash/noop';
import replace from 'lodash/replace';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import every from 'lodash/every';
import { toast } from 'react-toastify';

import { ROLES, replaceEnv } from './constants';
import { refreshAccessToken } from '../actions/all';

/**
 * Axios Service contains base axios configuration and two separate instance:
 * "axios" - for all request
 * "axiosWithCache" - for dictionaries
 */

nativeAxios.defaults.headers.common['Content-Type'] = 'application/json';

export const axios = nativeAxios;
let currentStore;
let currentHistory;
let currentI18n;

export const init = ({ store, history, i18n }) => {
  currentStore = store;
  currentHistory = history;
  currentI18n = i18n;
};

// request.url: locale
// const lastLocalesUsedForDictionaries = {};
//
// const axiosCache = setupCache({
//   maxAge: process.env.REACT_APP_MOTOTRADE_API_URL || 900000,
//   exclude: { query: false },
//   invalidate: async (config, request) => {
//     const method = toLower(request.method);
//     const currentLocale = currentStore.getState().userData?.locale;
//     const isDictionary = includes(request.url, '/Dictionaries/');
//     let hasLocaleChange = false;
//
//     if (
//       isDictionary &&
//       lastLocalesUsedForDictionaries[request.url] !== currentLocale
//     ) {
//       lastLocalesUsedForDictionaries[request.url] = currentLocale;
//       hasLocaleChange = true;
//     }
//
//     if (
//       some(
//         [
//           // we should cache only GET requests
//           method !== 'get',
//           // we should clear cache on language change
//           hasLocaleChange,
//           // provide feature to clear cache by custom axios option 'clearCacheEntry'
//           // see https://www.npmjs.com/package/axios-cache-adapter#invalidate-cache-entries
//           request.clearCacheEntry === true,
//         ],
//         (value) => Boolean(value)
//       )
//     ) {
//       await config.store.removeItem(config.uuid);
//     }
//   },
// });

/**
 * Special 'axios' instance with cache possibility, use it to cache your dictionary requests, or other request that not change frequently.
 * @type {AxiosInstance}
 */
export const axiosWithCache = axios.create({
  // adapter: axiosCache.adapter,
});

const fallbackAPIUrl = `${window.origin}/api`;
export const portalApiUrl = replaceEnv(
  replace(process.env.REACT_APP_PORTAL_API_URL || fallbackAPIUrl, /\/+$/, '')
);
export const pdfApiUrl = replaceEnv(
  replace(process.env.REACT_APP_PDF_API_URL || fallbackAPIUrl, /\/+$/, '')
);
export const changeTrackerApiUrl = replaceEnv(
  replace(
    process.env.REACT_APP_CHANGETRACKER_API_URL || fallbackAPIUrl,
    /\/+$/,
    ''
  )
);
export const motoTradeApiUrl = replaceEnv(
  replace(process.env.REACT_APP_MOTOTRADE_API_URL || fallbackAPIUrl, /\/+$/, '')
);
export const arvalIdentityApiUrl = replaceEnv(
  replace(
    process.env.REACT_APP_ARVAL_IDENTITY_API_URL || fallbackAPIUrl,
    /\/+$/,
    ''
  )
);
export const arvalBackOfficeApiUrl = replaceEnv(
  replace(
    process.env.REACT_APP_ARVAL_BAKC_OFFICE_API_URL || fallbackAPIUrl,
    /\/+$/,
    ''
  )
);

export const arvalProductsManagerApiUrl = replaceEnv(
  replace(
    process.env.REACT_APP_ARVAL_PRODUCTS_MANAGER_API_URL || fallbackAPIUrl,
    /\/+$/,
    ''
  )
);

/**
 * @param path {string}
 * @returns {string}
 */
export const portalUrl = (path) => `${portalApiUrl}${path}`;

/**
 * @param path {string}
 * @returns {string}
 */
export const pdfUrl = (path) => `${pdfApiUrl}${path}`;

/**
 * @param path {string}
 * @returns {string}
 */
export const changeTrackerUrl = (path) => `${changeTrackerApiUrl}${path}`;

/**
 * @param path {string}
 * @returns {string}
 */
export const motoTradeUrl = (path) => `${motoTradeApiUrl}${path}`;

/**
 * @param path {string}
 * @returns {string}
 */
export const arvalIdentityUrl = (path) => `${arvalIdentityApiUrl}${path}`;

/**
 * @param path {string}
 * @returns {string}
 */
export const arvalBackOfficeUrl = (path) => `${arvalBackOfficeApiUrl}${path}`;

/**
 * @param path {string}
 * @returns {string}
 */
export const arvalProductsManagerUrl = (path) =>
  `${arvalProductsManagerApiUrl}${path}`;

/**
 * @param handler {Function} - external handler of the error
 * @param log {Boolean} - should I log the error?
 * @return {Function}
 */
export const handleErrorWithPromise =
  (handler = noop, log = true) =>
  (err) => {
    if (log) {
      console.error(err);
    }

    handler(err);
    // we should keep promise chain to be available for next .catch chains
    return Promise.reject(err);
  };

export const getAuthHeaderContent = (token) => `Bearer ${token}`;

export const setAuthHeader = (token) => {
  if (isString(token)) {
    axios.defaults.headers.common.Authorization = getAuthHeaderContent(token);
    axiosWithCache.defaults.headers.common.Authorization =
      getAuthHeaderContent(token);
  } else {
    delete axios.defaults.headers.common.Authorization;
    delete axiosWithCache.defaults.headers.common.Authorization;
  }
};

export const parseJWT = (accessToken) => {
  // key for user roles in tokenData, just the way it is on BE ¯\_(ツ)_/¯
  // hope this doesn't change a lot.
  const roleKey =
    'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
  const requiredFields = [
    'email',
    'userId',
    roleKey,
    'userCountries',
    'localization',
  ];
  let tokenData;
  let testRequiredFields = [false];

  try {
    tokenData = jwtDecode(accessToken);
    testRequiredFields = map(requiredFields, (field) =>
      tokenData.hasOwnProperty(field)
    );

    if (!every(testRequiredFields, Boolean)) {
      tokenData = null;
    }
  } catch (error) {
    console.error(
      'Error while parsing JWT token',
      requiredFields,
      testRequiredFields,
      error
    );
    tokenData = null;
  }

  if (tokenData) {
    const countries = tokenData.userCountries;
    // BE can't handle forcing roles as array of strings if there is only one element in JWT,
    // so we must transform it into stable data structure for the sake of integrity of this app
    if (!isArray(tokenData[roleKey]) && isString(tokenData[roleKey])) {
      tokenData[roleKey] = [tokenData[roleKey]];
    }

    return {
      email: tokenData.email,
      username: replace(tokenData.email, /@.*$/, ''),
      userId: tokenData.userId,
      locale: tokenData.localization,
      roles: map(tokenData[roleKey], (role) => ROLES[role]),
      countries,
    };
  }

  return null;
};

// we can only refresh token for one request at a time
let isRefreshingToken = false;

const interceptor = (error) => {
  console.error(error);
  const originalRequest = error.config;
  const authData = currentStore?.getState().authData;

  // we need to redirect if refresh token failed or there is no auth data
  if (
    error.response?.status === 401 &&
    (!authData || originalRequest.url.match(/token\/refresh/gi))
  ) {
    currentHistory.push('/login');

    return Promise.reject(error);
  }

  if (
    error.response?.status === 401 &&
    !originalRequest._retry &&
    authData.accessToken &&
    authData.refreshToken &&
    !isRefreshingToken
  ) {
    originalRequest._retry = true;
    isRefreshingToken = true;

    toast.info(currentI18n.t('error_auth_expired'));
    const { accessToken, refreshToken } = authData;
    const data = {
      accessToken: { token: accessToken },
      refreshToken,
    };

    return currentStore
      .dispatch(refreshAccessToken(data))
      .then((res) => {
        if (res) {
          isRefreshingToken = false;

          originalRequest.headers.Authorization = getAuthHeaderContent(
            res.accessToken
          );

          toast.success(currentI18n.t('error_auth_reauthorize_success'));

          return axios(originalRequest);
        }
        return null;
      })
      .catch(() => {
        isRefreshingToken = false;

        toast.error(currentI18n.t('error_auth_reauthorize_failed'));

        currentHistory.push('/login');
      });
  }

  if (
    error.response?.status === 401 &&
    !originalRequest._retry &&
    isRefreshingToken
  ) {
    return new Promise((resolve) => {
      originalRequest._retry = true;
      let token;
      const debounceInterval = () => {
        if (!isRefreshingToken) {
          clearInterval(token);
          token = null;

          setTimeout(() => {
            originalRequest.headers.Authorization =
              axios.defaults.headers.common.Authorization;

            resolve(axios(originalRequest));
          }, 500);
        }
      };

      token = setInterval(debounceInterval, 2000);
    });
  }

  return Promise.reject(error);
};

axios.interceptors.response.use((response) => {
  return response;
}, interceptor);

axiosWithCache.interceptors.response.use((response) => {
  return response;
}, interceptor);
