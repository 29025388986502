import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import flow from 'lodash/flow';
import noop from 'lodash/noop';
import { withTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { allActions } from '../actions';
import { ROLES } from './constants';

export const AUTH_PROP_TYPES = {};

// we want to display error only once!
let activePointOfSalesErrorRendered = false;

export const withUserData = (WrappedComponent) => {
  class WithUserData extends Component {
    static displayName = `WithUserData(${
      WrappedComponent.displayName || WrappedComponent.name
    })`;

    static propTypes = AUTH_PROP_TYPES;

    componentDidMount = noop;

    render() {
      const { actions, userData, userPublicData, t, ...rest } = this.props;
      const {
        availablePointOfSales,
        availableCountries,
        roles,
        username,
        email,
        userId,
        userPriviliges,
      } = userData;
      const { activePointOfSales, locale } = userPublicData;
      const userDataService = {
        userId,
        username,
        userPriviliges,
        email,
        locale,
        roles,
        availableRoles: ROLES,
        availableCountries,
        availablePointOfSales,
        setPointOfSales: actions.setPointOfSales,
        setUserPriviliges: actions.setUserPriviliges,
        activePointOfSales,
      };
      // we should not display content if there is no point of sales available
      if (!activePointOfSales && userId) {
        if (!activePointOfSalesErrorRendered) {
          activePointOfSalesErrorRendered = true;
          return (
            <div className='container'>
              <div className='row l-margin-top-20'>
                <div className='col-xs-12'>
                  <div className='alert-message alert alert-danger'>
                    <Trans i18nKey='error_page_400_no_point_of_sales'>
                      You don&apos;t have any associated Point of Sales. Please{' '}
                      <Link to='/login' className='strong'>
                        login
                      </Link>{' '}
                      and try again or contact your administrator.
                    </Trans>
                  </div>
                </div>
              </div>
            </div>
          );
        }
        activePointOfSalesErrorRendered = false;
        return null;
      }

      activePointOfSalesErrorRendered = false;
      return (
        <WrappedComponent userDataService={userDataService} t={t} {...rest} />
      );
    }
  }

  const mapStateToProps = ({ userData, authData, userPublicData }) => ({
    userData,
    authData,
    userPublicData,
  });

  const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
      {
        ...allActions,
      },
      dispatch
    ),
  });

  return flow([
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
  ])(WithUserData);
};
