// WARNING!
// This file is generated automatically by 'makolab-routes' npm script, please DO NOT edit it!
// If you want to add new component to routes-map, create a new file
// with it's name matching: 'COMPONENT_TYPE.container.js' in 'src/components' directory,
// where 'COMPONENT_TYPE' is any kebab-case name

import React from 'react';

export const routesMap = {
  TransactionsPage: React.lazy(() => import('./app/pages/checkout/transactions/transactions.page.js')),
  TranslationsPage: React.lazy(() => import('./app/pages/checkout/translations/translations.page.js')),
  CountriesPage: React.lazy(() => import('./app/pages/configuration/countries/countries.page.js')),
  CountryAddPage: React.lazy(() => import('./app/pages/configuration/countries/country-add.page.js')),
  CountryEditPage: React.lazy(() => import('./app/pages/configuration/countries/country-edit.page.js')),
  CountryViewPage: React.lazy(() => import('./app/pages/configuration/countries/country-view.page.js')),
  PosAddPage: React.lazy(() => import('./app/pages/configuration/point-of-sales/pos-add.page.js')),
  PosDetailsPage: React.lazy(() => import('./app/pages/configuration/point-of-sales/pos-details.page.js')),
  PosEditPortalImagesPage: React.lazy(() => import('./app/pages/configuration/point-of-sales/pos-edit-portal-images.page.js')),
  PosEditPortalLogoPage: React.lazy(() => import('./app/pages/configuration/point-of-sales/pos-edit-portal-logo.page.js')),
  PosEditPortalPlayerImagesPage: React.lazy(() => import('./app/pages/configuration/point-of-sales/pos-edit-portal-player-images.page.js')),
  PosEditPage: React.lazy(() => import('./app/pages/configuration/point-of-sales/pos-edit.page.js')),
  PosPage: React.lazy(() => import('./app/pages/configuration/point-of-sales/pos.page.js')),
  EditMyProfilePage: React.lazy(() => import('./app/pages/configuration/profile/edit-my-profile.page.js')),
  MyProfilePage: React.lazy(() => import('./app/pages/configuration/profile/my-profile.page.js')),
  UserEditPage: React.lazy(() => import('./app/pages/configuration/users/user-edit.page.js')),
  UserViewPage: React.lazy(() => import('./app/pages/configuration/users/user-view.page.js')),
  UsersPage: React.lazy(() => import('./app/pages/configuration/users/users.page.js')),
  ExternalLoginPage: React.lazy(() => import('./app/pages/external-login.page.js')),
  LoginPage: React.lazy(() => import('./app/pages/login.page.js')),
  MotorTradePage: React.lazy(() => import('./app/pages/logs/motortrade/motor-trade.page.js')),
  SeoAddPage: React.lazy(() => import('./app/pages/manage-portal/seo/seo-add.page.js')),
  SeoDetailsPage: React.lazy(() => import('./app/pages/manage-portal/seo/seo-details.page.js')),
  SeoEditPage: React.lazy(() => import('./app/pages/manage-portal/seo/seo-edit.page.js')),
  SeoPage: React.lazy(() => import('./app/pages/manage-portal/seo/seo.page.js')),
  UploadPhotosPage: React.lazy(() => import('./app/pages/manage-portal/upload-photos/upload-photos.page.js')),
  NoAccessPage: React.lazy(() => import('./app/pages/no-access.page.js')),
  NoMatchPage: React.lazy(() => import('./app/pages/no-match.page.js')),
  OfferDeletedPage: React.lazy(() => import('./app/pages/offer-deleted.page.js')),
  OfferAddFromFilePage: React.lazy(() => import('./app/pages/offers/offer-add-from-file.page.js')),
  OfferAddMatrixFromFilePage: React.lazy(() => import('./app/pages/offers/offer-add-matrix-from-file.page.js')),
  OfferAddWithoutPosPage: React.lazy(() => import('./app/pages/offers/offer-add-without-pos.page.js')),
  OfferAddPage: React.lazy(() => import('./app/pages/offers/offer-add.page.js')),
  OfferEditAssignPhotosPage: React.lazy(() => import('./app/pages/offers/offer-edit-assign-photos.page.js')),
  OfferEditDamagesPage: React.lazy(() => import('./app/pages/offers/offer-edit-damages.page.js')),
  OfferEditEquipmentPage: React.lazy(() => import('./app/pages/offers/offer-edit-equipment.page.js')),
  OfferEditExportPage: React.lazy(() => import('./app/pages/offers/offer-edit-export.page.js')),
  OfferEditFinancePage: React.lazy(() => import('./app/pages/offers/offer-edit-finance.page.js')),
  OfferEditMaintenanceAddItemPage: React.lazy(() => import('./app/pages/offers/offer-edit-maintenance-add-item.page.js')),
  OfferEditMaintenanceEditItemPage: React.lazy(() => import('./app/pages/offers/offer-edit-maintenance-edit-item.page.js')),
  OfferEditMaintenancePage: React.lazy(() => import('./app/pages/offers/offer-edit-maintenance.page.js')),
  OfferEditPortalsPage: React.lazy(() => import('./app/pages/offers/offer-edit-portals.page.js')),
  OfferEditUploadPhotosPage: React.lazy(() => import('./app/pages/offers/offer-edit-upload-photos.page.js')),
  OfferEditVehicleDataPage: React.lazy(() => import('./app/pages/offers/offer-edit-vehicle-data.page.js')),
  OfferEditPage: React.lazy(() => import('./app/pages/offers/offer-edit.page.js')),
  OfferPublishOffersBulkPage: React.lazy(() => import('./app/pages/offers/offer-publish-offers-bulk.page.js')),
  OfferResetPricesPage: React.lazy(() => import('./app/pages/offers/offer-reset-prices.page.js')),
  OffersAllPosPage: React.lazy(() => import('./app/pages/offers/offers-all-pos.page.js')),
  OffersPage: React.lazy(() => import('./app/pages/offers/offers.page.js')),
  LabelsAddPage: React.lazy(() => import('./app/pages/promotions/labels/labels-add.page.js')),
  LabelsEditPage: React.lazy(() => import('./app/pages/promotions/labels/labels-edit.page.js')),
  LabelsPage: React.lazy(() => import('./app/pages/promotions/labels/labels.page.js')),
  PartnerCompaniesAddPage: React.lazy(() => import('./app/pages/promotions/partner-companies/partner-companies-add.page.js')),
  PartnerCompaniesEditPage: React.lazy(() => import('./app/pages/promotions/partner-companies/partner-companies-edit.page.js')),
  PartnerCompaniesPage: React.lazy(() => import('./app/pages/promotions/partner-companies/partner-companies.page.js')),
  PromotionParametersAddPage: React.lazy(() => import('./app/pages/promotions/promotion-parameters/promotion-parameters-add.page.js')),
  PromotionParametersEditPage: React.lazy(() => import('./app/pages/promotions/promotion-parameters/promotion-parameters-edit.page.js')),
  PromotionParametersPage: React.lazy(() => import('./app/pages/promotions/promotion-parameters/promotion-parameters.page.js')),
  VehicleGroupsAddPage: React.lazy(() => import('./app/pages/promotions/vehicle-groups/vehicle-groups-add.page.js')),
  VehicleGroupsEditPage: React.lazy(() => import('./app/pages/promotions/vehicle-groups/vehicle-groups-edit.page.js')),
  VehicleGroupsPage: React.lazy(() => import('./app/pages/promotions/vehicle-groups/vehicle-groups.page.js')),
  SsoPage: React.lazy(() => import('./app/pages/sso.page.js'))
};
