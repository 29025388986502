import {
  axios,
  axiosWithCache,
  handleErrorWithPromise,
  arvalIdentityUrl,
  setAuthHeader,
} from './axios.service';
import { DEFAULT_PAGE_SIZE } from './constants';

/**
 * @module ArvalIdentityApi
 */

const { CancelToken } = axios;

// https://confluence.makolab.net/display/CIDAU/Offer+Manager+%3E+Swagger+Arval+Identity+Api
// responsible for countries, point of sales, auth

export const v = ''; // version

// Auth, Profile, Users, Point of Sales
export const validateAccessTokenSource = CancelToken.source();
export const validateAccessToken = ({ accessToken }) => {
  setAuthHeader(accessToken);
  return axios
    .request({
      method: 'GET',
      url: arvalIdentityUrl('/Token/Validate'),
      params: {
        v,
      },
      cancelToken: validateAccessTokenSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const refreshAccessTokenSource = CancelToken.source();
export const refreshAccessToken = ({ accessToken, refreshToken }) =>
  axios
    .request({
      method: 'POST',
      url: arvalIdentityUrl('/Token/Refresh'),
      params: {
        v,
      },
      data: {
        accessToken,
        refreshToken,
      },
      headers: {
        // refresh should not contain authorization header
        Authorization: undefined,
      },
      cancelToken: refreshAccessTokenSource.token,
    })
    .catch(handleErrorWithPromise());

export const loginSource = CancelToken.source();
export const login = ({ email, password }) =>
  axios
    .request({
      method: 'POST',
      url: arvalIdentityUrl('/Login'),
      params: {
        v,
      },
      data: {
        email,
        password,
      },
      cancelToken: loginSource.token,
    })
    .catch(handleErrorWithPromise());

export const logoutSource = CancelToken.source();
export const logout = () =>
  axios.request({
    method: 'POST',
    url: arvalIdentityUrl('/ExternalLogin/Logout'),
    params: {
      v,
    },
    cancelToken: logoutSource.token,
  });

export const languageSource = CancelToken.source();
export const getLanguages = ({ clearCacheEntry = false } = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalIdentityUrl('/Languages'),
      params: {
        v,
      },
      cancelToken: languageSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const languageDictionarySource = CancelToken.source();
export const getLanguagesDictionary = ({ clearCacheEntry = false } = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalIdentityUrl('/Languages/Dictionary'),
      params: {
        v,
      },
      cancelToken: languageDictionarySource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const rolesDictionarySource = CancelToken.source();
export const getRolesDictionary = ({ clearCacheEntry = false } = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalIdentityUrl('/Roles/Dictionary'),
      params: {
        v,
      },
      cancelToken: rolesDictionarySource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const registerSource = CancelToken.source();
export const register = ({ email, password, confirmPassword }) =>
  axios
    .request({
      method: 'POST',
      url: arvalIdentityUrl('/Login'),
      params: {
        v,
      },
      data: {
        email,
        password,
        confirmPassword,
      },
      cancelToken: registerSource.token,
    })
    .catch(handleErrorWithPromise());

/**
 * PROFILE
 */

export const getProfileSource = CancelToken.source();
export const getProfile = () => {
  return axios
    .request({
      method: 'GET',
      url: arvalIdentityUrl('/Profile'),
      params: {
        v,
      },
      cancelToken: getProfileSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const putProfileSource = CancelToken.source();
export const putProfile = ({ localization }) =>
  axios
    .request({
      method: 'PUT',
      url: arvalIdentityUrl('/Profile'),
      params: {
        v,
      },
      data: {
        localization,
      },
      cancelToken: putProfileSource.token,
    })
    .catch(handleErrorWithPromise());

/**
 * USERS
 */

export const getUsersSource = CancelToken.source();
export const getUsers = ({
  orderBy,
  order,
  pageNumber,
  pageSize,
  firstName,
  lastName,
  role,
  email,
  localization,
  type,
  isLockedOut,
}) =>
  axios
    .request({
      method: 'GET',
      url: arvalIdentityUrl('/Users'),
      params: {
        pageNumber: Number.parseInt(pageNumber, 10) || 1,
        pageSize: Number.parseInt(pageSize, 10) || DEFAULT_PAGE_SIZE,
        orderBy: orderBy || null,
        orderDirection: order || 'asc',
        firstName: firstName || '',
        lastName: lastName || '',
        role: role || '',
        email: email || '',
        localization: localization || '',
        type: type || '',
        isLockedOut: isLockedOut || false,
        v,
      },
      cancelToken: getUsersSource.token,
    })
    .catch(handleErrorWithPromise());

export const getUserSource = CancelToken.source();
export const getUser = ({ id }) =>
  axios
    .request({
      method: 'GET',
      url: arvalIdentityUrl(`/Users/${id}`),
      params: {
        v,
      },
      cancelToken: getUserSource.token,
    })
    .catch(handleErrorWithPromise());

export const putUserSource = CancelToken.source();
export const putUser = ({ id, localization }) =>
  axios
    .request({
      method: 'PUT',
      url: arvalIdentityUrl(`/Users/${id}`),
      params: {
        v,
      },
      data: {
        localization,
      },
      cancelToken: putUserSource.token,
    })
    .catch(handleErrorWithPromise());

export const lockUserSource = CancelToken.source();
export const lockUser = ({ id }) =>
  axios
    .request({
      method: 'PUT',
      url: arvalIdentityUrl(`/Users/Lock/${id}`),
      params: {
        v,
      },
      data: {},
      cancelToken: lockUserSource.token,
    })
    .catch(handleErrorWithPromise());

export const unlockUserSource = CancelToken.source();
export const unlockUser = ({ id }) =>
  axios
    .request({
      method: 'PUT',
      url: arvalIdentityUrl(`/Users/Unlock/${id}`),
      params: {
        v,
      },
      data: {},
      cancelToken: unlockUserSource.token,
    })
    .catch(handleErrorWithPromise());

/**
 * UserLocalizations
 */

export const getCurrentUserLocalizationsSource = CancelToken.source();
export const getCurrentUserLocalizations = () =>
  axios
    .request({
      method: 'GET',
      url: arvalIdentityUrl(`/UserLocalizations`),
      params: {
        v,
      },
      cancelToken: getCurrentUserLocalizationsSource.token,
    })
    .catch(handleErrorWithPromise());

export const getCurrentUserLocalizationsCountriesSource = CancelToken.source();
export const getCurrentUserLocalizationsCountries = () =>
  axios
    .request({
      method: 'GET',
      url: arvalIdentityUrl(`/UserLocalizations/Countries`),
      params: {
        v,
      },
      cancelToken: getCurrentUserLocalizationsCountriesSource.token,
    })
    .catch(handleErrorWithPromise());

export const getUserLocalizationsSource = CancelToken.source();
export const getUserLocalizations = ({ id, pageSize = DEFAULT_PAGE_SIZE }) =>
  axios
    .request({
      method: 'GET',
      url: arvalIdentityUrl(`/UserLocalizations/${id}`),
      params: {
        v,
        pageSize,
      },
      cancelToken: getUserLocalizationsSource.token,
    })
    .catch(handleErrorWithPromise());

export const getUserLocalizationsNoUserSource = CancelToken.source();
export const getUserLocalizationsNoUser = ({ pageSize = DEFAULT_PAGE_SIZE }) =>
  axios
    .request({
      method: 'GET',
      url: arvalIdentityUrl(`/UserLocalizations/Users`),
      params: {
        v,
        pageSize,
      },
      cancelToken: getUserLocalizationsNoUserSource.token,
    })
    .catch(handleErrorWithPromise());

export const deleteUserLocalizationsSource = CancelToken.source();
export const deleteUserLocalizations = ({ id }) =>
  axios
    .request({
      method: 'DELETE',
      url: arvalIdentityUrl(`/UserLocalizations/${id}`),
      params: {
        v,
      },
      cancelToken: deleteUserLocalizationsSource.token,
    })
    .catch(handleErrorWithPromise());

export const addUserLocalizationsSource = CancelToken.source();
export const addUserLocalizations = ({ userId, pointOfSaleId }) =>
  axios
    .request({
      method: 'POST',
      url: arvalIdentityUrl(`/UserLocalizations`),
      params: {
        v,
      },
      data: {
        userId: Number.parseFloat(userId),
        pointOfSaleId: Number.parseFloat(pointOfSaleId),
      },
      cancelToken: addUserLocalizationsSource.token,
    })
    .catch(handleErrorWithPromise());

/**
 * COUNTRIES
 */

export const getCountriesSource = CancelToken.source();
export const getCountries = ({
  pageNumber,
  pageSize,
  clearCacheEntry = false,
} = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalIdentityUrl('/Countries'),
      params: {
        pageNumber: Number.parseInt(pageNumber, 10) || 1,
        pageSize: Number.parseInt(pageSize, 10) || DEFAULT_PAGE_SIZE,
        v,
      },
      cancelToken: getCountriesSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const createCountrySource = CancelToken.source();
export const createCountry = ({ formData }) =>
  axios
    .request({
      method: 'POST',
      url: arvalIdentityUrl('/Countries'),
      params: {
        v,
      },
      data: {
        code: formData.code,
        name: formData.name,
        vatPercentage: formData.vatPercentage,
        administrationFee: formData.administrationFee,
        showVehicleHistory: formData.showVehicleHistory,
        countryGroupId: formData.countryGroupId,
        // powerLabel: formData.powerLabel,
        // powerMultiplier: formData.powerMultiplier,
      },
      cancelToken: createCountrySource.token,
    })
    .catch(handleErrorWithPromise());

export const editCountrySource = CancelToken.source();
export const editCountry = ({ id, formData }) =>
  axios
    .request({
      method: 'PUT',
      url: arvalIdentityUrl(`/Countries/${id}`),
      params: {
        v,
      },
      data: {
        code: formData.code,
        name: formData.name,
        vatPercentage: formData.vatPercentage,
        administrationFee: formData.administrationFee,
        showVehicleHistory: formData.showVehicleHistory,
        countryGroupId: formData.countryGroupId,
        // powerLabel: formData.powerLabel,
        // powerMultiplier: formData.powerMultiplier,
      },
      cancelToken: editCountrySource.token,
    })
    .catch(handleErrorWithPromise());

export const getCountrySource = CancelToken.source();
export const getCountry = ({ id }) =>
  axios
    .request({
      method: 'GET',
      url: arvalIdentityUrl(`/Countries/${id}`),
      params: {
        v,
      },
      cancelToken: getCountrySource.token,
    })
    .catch(handleErrorWithPromise());

export const deleteCountrySource = CancelToken.source();
export const deleteCountry = ({ id }) =>
  axios
    .request({
      method: 'DELETE',
      url: arvalIdentityUrl(`/Countries/${id}`),
      params: {
        v,
      },
      cancelToken: deleteCountrySource.token,
    })
    .catch(handleErrorWithPromise());

/**
 * POINT OF SALES
 */

export const getAllPointOfSalesSource = CancelToken.source();
export const getAllPointOfSales = ({
  pageSize,
  clearCacheEntry = false,
} = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalIdentityUrl('/PointOfSales'),
      params: {
        v,
        pageSize: pageSize || DEFAULT_PAGE_SIZE,
      },
      cancelToken: getAllPointOfSalesSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const getPointOfSalesSource = CancelToken.source();
export const getPointOfSales = ({
  countryId,
  orderBy,
  order,
  pageNumber,
  pageSize,
}) =>
  axios
    .request({
      method: 'GET',
      url: arvalIdentityUrl('/PointOfSales'),
      params: {
        countryId,
        pageNumber: Number.parseInt(pageNumber, 10) || 1,
        pageSize: Number.parseInt(pageSize, 10) || DEFAULT_PAGE_SIZE,
        orderBy: orderBy || null,
        order: order || 'asc',
        v,
      },
      cancelToken: getPointOfSalesSource.token,
    })
    .catch(handleErrorWithPromise());

export const createPointOfSalesSource = CancelToken.source();
export const createPointOfSales = ({ formData }) =>
  axios
    .request({
      method: 'POST',
      url: arvalIdentityUrl('/PointOfSales'),
      params: {
        v,
      },
      data: {
        code: formData.code,
        name: formData.name,
        countryId: formData.countryId,
      },
      cancelToken: createPointOfSalesSource.token,
    })
    .catch(handleErrorWithPromise());

export const editPointOfSalesSource = CancelToken.source();
export const editPointOfSales = ({ id, formData }) =>
  axios
    .request({
      method: 'PUT',
      url: arvalIdentityUrl(`/PointOfSales/${id}`),
      params: {
        v,
      },
      data: {
        code: formData.code,
        name: formData.name,
        countryId: formData.countryId,
        getImagesFromMt: formData.getImagesFromMt,
      },
      cancelToken: editPointOfSalesSource.token,
    })
    .catch(handleErrorWithPromise());

export const getPointOfSaleSource = CancelToken.source();
export const getPointOfSale = ({ id }) =>
  axios
    .request({
      method: 'GET',
      url: arvalIdentityUrl(`/PointOfSales/${id}`),
      params: {
        v,
      },
      cancelToken: getPointOfSaleSource.token,
    })
    .catch(handleErrorWithPromise());

export const deletePointOfSaleSource = CancelToken.source();
export const deletePointOfSale = ({ id }) =>
  axios
    .request({
      method: 'DELETE',
      url: arvalIdentityUrl(`/PointOfSales/${id}`),
      params: {
        v,
      },
      cancelToken: deletePointOfSaleSource.token,
    })
    .catch(handleErrorWithPromise());

export const getPriviligesSource = CancelToken.source();
export const getPriviliges = ({ countryId }) =>
  axios
    .request({
      method: 'GET',
      url: arvalIdentityUrl(`/Priviliges`),
      params: {
        countryId,
        v,
      },
      cancelToken: getPriviligesSource.token,
    })
    .catch(handleErrorWithPromise());

export const getPriviligesAsDictionarySource = CancelToken.source();
export const getPriviligesAsDictionary = ({ countryId }) =>
  axios
    .request({
      method: 'GET',
      url: arvalIdentityUrl(`/Priviliges/AsDictionary`),
      params: {
        countryId,
        v,
      },
      cancelToken: getPriviligesAsDictionarySource.token,
    })
    .catch(handleErrorWithPromise());
